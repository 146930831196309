import React, { useState } from 'react';
import MasterForm3Label from './Masters/Modal/MasterForm3Label';
import MasterForm4Label from './Masters/Modal/MasterForm4Label';
import MasterFormSubCast from './Masters/Modal/MasterFormSubCast';
import MasterFormStudentGrade from './Masters/Modal/MasterFormStudentGrade';
import MasterFormClass from './Masters/Modal/MasterFormClass';
import MasterFormDivision from './Masters/Modal/MasterFormDivision';
import MasterFormExam from './Masters/Modal/MasterFormExam';
import MasterFormSubExam from './Masters/Modal/MasterFormSubExam';
import MasterFormStudent from './Administration/AdministratorMaster/MasterFormStudent';
import MasterFormStudentTable from './Administration/AdministratorMaster/MasterFormStudentTable';
import MasterFormEmployee from './Masters/Modal/MasterFormEmployee';
import { Route, Routes } from 'react-router-dom';
import VoucherEntryForm from './AccountMaster/Modal/VoucherEntryForm';
import MasterFormAccountGroup from './AccountMaster/Modal/MasterFormAccountGroup';
import MasterFormAccountMaster from './AccountMaster/Modal/MasterFormAccountMaster';
import MasterFormNarrationMaster from './AccountMaster/Modal/MasterFormNarrationMaster';
import VoucherEntryTable from './AccountMaster/Modal/VoucherEntryTable';
import AccountLedger from './AccountMaster/Reports/AccountLedger';
import ReceiptAndPayment from './AccountMaster/Reports/ReceiptAndPayment';
import IncomeAndExpenditure from './AccountMaster/Reports/IncomeAndExpenditure';
import BalanceSheet from './AccountMaster/Reports/BalanceSheet';
import MasterFormEmployeeTable from './Masters/Modal/MasterFormEmployeeTable';
import KirdReport from './AccountMaster/Reports/KirdReport';
import ClassTeacherAllocation from './Academic/Modal/ClassTeacherAllocation';
import StudentAttendance from './Academic/Catalog/StudentAttendance';
import StudentAadhar from './Administration/SaralDataUpdate/StudentAadhar';
import RollNoAssign from './Academic/Modal/RollNoAssign';
import LeavingCertificateTable from './Administration/AdministratorMaster/LeavingCertificateTable';
import LeavingCertificateForm from './Administration/AdministratorMaster/LeavingCertificateForm';
import StudentBankDetails from './Administration/AdministratorMaster/StudentBankDetails';
import CasteCategoryAssignment from './Administration/AdministratorMaster/CasteCategoryAssignment';
import MinorityStatusAssignment from './Administration/AdministratorMaster/MinorityStatusAssignment';
import ParentPhone from './Administration/AdministratorMaster/ParentPhone';
import StudentCWSN from './Administration/SaralDataUpdate/StudentCWSN';
import StudentMedium from './Administration/SaralDataUpdate/StudentMedium';
import SemiEnglishStudents from './Administration/SaralDataUpdate/SemiEnglishStudents';
import BplStudents from './Administration/SaralDataUpdate/BplStudents';
import StudentAdmType from './Administration/SaralDataUpdate/StudentAdmType';
import SubjectAllocation from './Academic/Modal/SubjectAllocation';
import LessonPlan from './Academic/Catalog/LessonPlan';
import MonthlyLessonPlan from './Academic/Catalog/MonthlyLessonPlan';
import StudentBatch from './Academic/Catalog/StudentBatch';
import Bonafide from './Administration/Report/Bonafide';
import PageNotFound from './CommonComponent/PageNotFound';
import StudentReligion from './Administration/SaralDataUpdate/StudentReligion';
import MonthlyWorkDays from './Academic/Catalog/MonthlyWorkDays';
import InstituteMasterTable from './Masters/Modal/InstituteMasterTable';
import StudentSaral from './Administration/SaralDataUpdate/StudentSaral';
import InstituteMaster from './Masters/Modal/InstituteMaster';
import MasterReport from './Masters/Reports/MasterReport';
import AccountMasterReport from './AccountMaster/Reports/AccountMasterReport';
import LcPrint from './Administration/Report/LcPrint';
import ExamSubExamAllocation from './Exam/ExamMaster/ExamSubExamAllocation';
import ExamMarkAllocation from './Exam/ExamMaster/ExamMarkAllocation';
import SubjectAllocationReport from './Academic/AcademicReport/SubjectAllocationReport';
import ClassTeacherAllocationReport from './Academic/AcademicReport/ClassTeacherAllocationReport';
import CategorywiseStudReport from './Administration/Report/CategorywiseStudReport';
import MinorityReport from './Administration/Report/MinorityReport';
import GeneralRegister from './Administration/Report/GeneralRegister';
import FeeMaster from './Masters/Modal/FeeMaster';
import FeeDemand from './FeeManagement/Masters/FeeDemand';
import ExamMarkEntry from './Exam/ExamMaster/ExamMarkEntry';
import FeeReceipt from './FeeManagement/Masters/FeeReceipt';
import FeeReceiptTable from './FeeManagement/Masters/FeeReceiptTable';
import FeeReceiptSummary from './FeeManagement/FeeReports/FeeReceiptSummary';
import FeeDueList from './FeeManagement/FeeReports/FeeDueList';
import BulkFeeReceipt from './FeeManagement/FeeReports/BulkFeeReceipt';
import FeeDemandSummaryReport from './FeeManagement/FeeReports/FeeDemandSummaryReport';
import CatalogReport from './Academic/CatalogReport/CatalogReport';
import ExamAllocationReport from './Exam/ExamReport/ExamAllocationReport';
import CatalogEndOfMonth from './Academic/CatalogReport/CatalogEndOfMonth';
import CatalogAllStd from './Academic/CatalogReport/CatalogAllStd';
import CatalogSubject from './Academic/CatalogReport/CatalogSubject';
import Dashboard from './Dashboard';
import ExamMarkAllocationReport from './Exam/ExamReport/ExamMarkAllocationReport';
import ReceiptAndChalanReport from './AccountMaster/Modal/ReceiptAndChalanReport';
import ExamResultSetting from './Exam/ExamReport/ExamResultSetting';
import SubjectMarkSheet from './Exam/ExamReport/SubjectMarkSheet';
import PrapatraA from './Exam/ExamReport/PrapatraA';
import FreqDistribution from './Exam/ExamReport/FreqDistribution';
import ProgressCard from './Exam/ExamReport/ProgressCard';
import SansthaNotice from './Sanstha/SansthaAdministration/SansthaNotice';
import SubjectAllocationToTeacher from './Academic/Modal/SubjectAllocationToTeacher';
import TaslamatCertificate from './AccountMaster/Certificates/TaslamatCertificate';
import LoanCertificate from './AccountMaster/Certificates/LoanCertificate';
import AnamatCertificate from './AccountMaster/Certificates/AnamatCertificate';
import MudranCertificate from './AccountMaster/Certificates/MudranCertificate';
import DengiPavati from './FeeManagement/Masters/DengiPavati';
import DengiPavatiTable from './FeeManagement/Masters/DengiPavatiTable';
import SansthaReceipt from './FeeManagement/Masters/SansthaReceipt';
import SansthaReceiptTable from './FeeManagement/Masters/SansthaReceiptTable';
import SingleSubExamWiseReport from './Exam/ExamReport/SingleSubExamWiseReport';
import CumulativeRecord from './Exam/ExamReport/CumulativeRecord';
import SubjectToTeacherAllocationReport from './Academic/AcademicReport/SubjectToTeacherAllocationReport';
import DengiPavatiReport from './FeeManagement/FeeReports/DengiPavatiReport';
import AdmissionFormSale from './Admission/AdmissionMaster/AdmissionFormSale';
import GeneralRegisterChecklist from './Administration/Report/GeneralRegisterChecklist';
import PramotShiftStudents from './Administration/AdministratorMaster/PramotShiftStudents';
import TransferStudents from './Administration/AdministratorMaster/TransferStudents';
import AdmissionFormReceive from './Admission/AdmissionMaster/AdmissionFormReceive';
import AdmissionFormCancel from './Admission/AdmissionMaster/AdmissionFormCancel';
import AdmissionConfirm from './Admission/AdmissionMaster/AdmissionConfirm';
import DengiPavatiPohochReport from './FeeManagement/FeeReports/DengiPavatiPohochReport';
import DengiPavatiPohoch from './FeeManagement/FeeReports/DengiPavatiPohoch';
import FeeReceiptSummaryExcelReport from './FeeManagement/FeeReports/FeeReceiptSummaryExcelReport';
import EmployeeReport from './Masters/Reports/EmployeeReport';
import StudentEntryExcel from './Masters/Modal/StudentEntryExcel';
import StudentsBulkLc from './Administration/Report/StudentsBulkLc';
import LcDefault from './Administration/Report/LcDefault';
import AccountSummary from './AccountMaster/Reports/AccountSummary';
import UserErrorSubmissionForm from './ServicePanel/Modal/UserErrorSubmissionForm';
import AdmissionFormReceiveTable from './Admission/AdmissionMaster/AdmissionFormReceiveTable';
import AdmissionMeritList from './Admission/Report/AdmissionMeritList';
import ManualLeavingCertificateForm from './Administration/AdministratorMaster/ManualLeavingCertificateForm';
import StudentWiseSubjectAllocation from './Academic/Modal/StudentWiseSubjectAllocation';
import ParentData from './Administration/AdministratorMaster/ParentData';
import StudentNationalCode from './Administration/SaralDataUpdate/StudentNationalCode';
import StudentNationalCodeReport from './Administration/Report/StudentNationalCodeReport';
import TermWiseObservationReport from './Exam/ExamReport/TermWiseObservationReport';
import SubjectWiseObservationReport from './Exam/ExamReport/SubjectWiseObservationReport';

//ToggleSideMenu
import ToggleSideMenu from './CommonComponent/ToggleSideMenu';
import StudentAllowences from './Administration/SaralDataUpdate/StudentAllowences';
import ManualBonafide from './Administration/Report/ManualBonafide';
import LcSummary from './Administration/Report/LcSummary';
import StudentsMotherTongue from './Administration/AdministratorMaster/StudentsMotherTongue';
import StudentWiseFeeDemand from './FeeManagement/Masters/StudentWiseFeeDemand';
import StudentWiseSubjectReport from './Academic/AcademicReport/StudentWiseSubjectReport';
import MasterForm2Label from './Masters/Modal/MasterForm2Label';
import YearlyFeeCollection from './FeeManagement/FeeReports/YearlyFeeCollection';
import EndowmentMaster from './Sanstha/SansthaEndowment/Master/EndowmentMaster';
import EndowmentMasterTable from './Sanstha/SansthaEndowment/Master/EndowmentMasterTable';
import EndowmentTransaction from './Sanstha/SansthaEndowment/Transaction/EndowmentTransaction';
import SetSubjectSerial from './Academic/CatalogReport/SetSubjectSerial';
import SansthaFeeReceiptSummary from './FeeManagement/FeeReports/SansthaFeeReceiptSummary';
import DemoPage from './Sanstha/SansthaEndowment/Master/DemoPage';
import EndowmentList from './Sanstha/SansthaEndowment/Report/EndowmentList';
import EndowmentWithConditions from './Sanstha/SansthaEndowment/Report/EndowmentWithConditions';
import StudentVillageUpdation from './Administration/AdministratorMaster/StudentVillageUpdation';
import EndowmentDashboard from './Sanstha/SansthaEndowment/EndowmentDashboard';
import StudentRTEResult from './Exam/ExamMaster/StudentRTEResult';
import StudInternalSeatNo from './Exam/ExamMaster/StudInternalSeatNo';
import CategorySummary from './Administration/Report/CategorySummary';
import AutoCompleteDemoPage from './FeeManagement/Masters/AutoCompleteDemoPage';
import AdmissionFormSaleReport from './Admission/Report/AdmissionFormSaleReport';
import AdmissionFormReceiveReport from './Admission/Report/AdmissionFormReceiveReport';
import ResultSheet from './Exam/ExamReport/ResultSheet';
import EndowmentInterestCalculation from './Sanstha/SansthaEndowment/Transaction/EndowmentInterestCalculation';
import SchoolWiseEndowment from './Sanstha/SansthaEndowment/Report/SchoolWiseEndowment';
import SansthaAwardDistribution from './Sanstha/SansthaEndowment/Report/SansthaAwardDistribution';
import EndowmentInterestReport from './Sanstha/SansthaEndowment/Report/EndowmentInterestReport';
import SansthaAwardBlank from './Sanstha/SansthaEndowment/Report/SansthaAwardBlank';
import SansthaAwardDistSummary from './Sanstha/SansthaEndowment/Report/SansthaAwardDistSummary';
import ClassWiseEndowmentReport from './Sanstha/SansthaEndowment/Report/ClassWiseEndowmentReport';
import SubjectWiseEndowmentReport from './Sanstha/SansthaEndowment/Report/SubjectWiseEndowmentReport';
import GenderWiseEndowmentReport from './Sanstha/SansthaEndowment/Report/GenderWiseEndowmentReport';
import EBCFeeDemand from './FeeManagement/Masters/EBCFeeDemand';
import EBCReport from './Academic/AcademicReport/EBCReport';
import EBCSummary from './Academic/AcademicReport/EBCSummary';
import CurrentStudentAgeList from './Academic/AcademicReport/CurrentStudentAgeListReport';
import ExamAttendance from './Exam/ExamMaster/AfterExam/ExamAttendance';
import TermWiseHealth from './Exam/ExamMaster/AfterExam/TermWiseHealth';
import EndowmentSubjectMaster from './Sanstha/SansthaEndowment/Master/EndowmentSubjectMaster';
import EndowmentRegisterReport from './Sanstha/SansthaEndowment/Report/EndowmentRegisterReport';
import SchoolWiseEndowWiseDetailReport from './Sanstha/SansthaEndowment/Report/SchoolWiseEndowWiseDetailReport';
import StudInternalSeatNoReport from './Exam/ExamReport/StudInternalSeatNoReport';
import LibraryMaster2LabelForm from './Library/LibraryMasters/LibraryMaster2LabelForm';
import LibraryMaster2LabelTable from './Library/LibraryMasters/LibraryMaster2LabelTable';
import TitleMaster from './Library/LibraryMasters/TitleMaster';
import TitleMasterTable from './Library/LibraryMasters/TitleMasterTable';
import RegisterTypeMaster from './Library/LibraryMasters/RegisterTypeMaster';
import RegisterTypeMasterTable from './Library/LibraryMasters/RegisterTypeMasterTable';
import AuthorMaster from './Library/LibraryMasters/AuthorMaster';
import AuthorMasterTable from './Library/LibraryMasters/AuthorMasterTable';
import AdminVoucherEntryPage from './Sanstha/SansthaAccount/AdminVoucherEntryPage';
import PublisherMaster from './Library/LibraryMasters/PublisherMaster';
import PublisherMasterTable from './Library/LibraryMasters/PublisherMasterTable';
import SubBudgetTypeMaster from './Library/LibraryMasters/SubBudgetTypeMaster';
import SubBudgetTypeMasterTable from './Library/LibraryMasters/SubBudgetTypeMasterTable';
import MemberTypeMaster from './Library/LibraryMasters/MemberTypeMaster';
import MemberTypeMasterTable from './Library/LibraryMasters/MemberTypeMasterTable';
import VendorMaster from './Library/LibraryMasters/VendorMaster';
import VendorMasterTable from './Library/LibraryMasters/VendorMasterTable';
import BinderMaster from './Library/LibraryMasters/BinderMaster';
import BinderMasterTable from './Library/LibraryMasters/BinderMasterTable';
import AccessionEntry from './Library/Acquisition/Master/AccessionEntry';
import AccessionEntryTable from './Library/Acquisition/Master/AccessionEntryTable';
import AccessionReport from './Library/Acquisition/Reports/AccessionReport';
import BookIssueReturn from './Library/Circulation/Masters/BookIssueReturn';
import WithdrawalEntry from './Library/Circulation/Masters/WithdrawalEntry';
import RegistrationApproval from './MobileApp/Module/RegistrationApproval';
import Notice from './MobileApp/Module/Notice';
import Event from './MobileApp/Module/Event';
import BookBankIssue from './Library/Circulation/Masters/BookBankIssue';
import HomeWork from './MobileApp/Module/HomeWork';
import LibraryMasterReport from './Library/LibraryReports/LibraryMasterReport';
import NoticeTable from './MobileApp/Module/NoticeTable';
import EventTable from './MobileApp/Module/EventTable';
import HomeWorkTable from './MobileApp/Module/HomeWorkTable';
import BookIssueRegister from './Library/Circulation/Reports/BookIssueRegister';
import DailyTransaction from './Library/Circulation/Reports/DailyTransaction';
import DefaulterList from './Library/Circulation/Reports/DefaulterList';
import BookReturnRegister from './Library/Circulation/Reports/BookReturnRegister';
import BookBarcode from './Library/Circulation/Reports/BookBarcode';
import SchoolStatistics from './Administration/UDISEReport/SchoolStatistics';
import StudAgeStatistics from './Administration/UDISEReport/StudAgeStatistics';
import UdiesEReport from './Administration/UDISEReport/UdiesEReport';
import BudgetCategories from './Budget/BudgetMaster/BudgetCategories';
import BudgetCategoryTable from './Budget/BudgetMaster/BudgetCategoryTable';
import BudgetAccountForm from './Budget/BudgetMaster/BudgetAccountForm';
import BudgetAccountTable from './Budget/BudgetMaster/BudgetAccountTable';
import BudgetEntryForm from './Budget/Transaction/BudgetEntryForm';
import SchoolWiseBudgetReport from './Budget/BudgetReports/SchoolWiseBudgetReport';
import WithdrawalEntryTable from './Library/Circulation/Masters/WithdrawalEntryTable';
import BudgetReport from './Budget/BudgetReports/BudgetReport';
import BudgetNoAndDateForm from './Budget/BudgetNoAndDateForm';
import BudgetConsolidatedDepartmentWiseReport from './Budget/BudgetReports/BudgetConsolidatedDepartmentWiseReport';
import BookBankReport from './Library/Circulation/Reports/BookBankReport';
import SansthaBudgetEntryForm from './Budget/Transaction/SansthaBudgetEntryForm';
import SainikSchoolHostelBudgetReport from './Budget/BudgetReports/SainikSchoolHostelBudgetReport';
import SansthaBudgetReport from './Budget/BudgetReports/SansthaBudgetReport';
import StudentsIdCard from './Administration/AdministratorMaster/StudentsIdCard';
import BudgetDashboard from './Budget/BudgetDashboard';
import BulkMarkAllocation from './Exam/ExamMaster/BulkMarkAllocation';
import StudentBoardSeatNo from './Exam/ExamMaster/StudentBoardSeatNo';
import StudentBoardSeatNoReport from './Exam/ExamReport/StudentBoardSeatNoReport';
import IdCardPrintingReport from './Administration/Report/IdCardPrintingReport';
import WithdrawalRegisterReport from './Library/Circulation/Reports/WithdrawalRegisterReport';
import MemberStatement from './Library/Circulation/Reports/MemberStatement';
import StudentRank from './Exam/Rank/StudentRank';
import BoardInternalAssessmentReport from './Exam/ExamReport/BoardInternalAssessmentReport';
import LibraryDashboard from './Library/LibraryDashboard';
import ClassMeritList from './Exam/Rank/ClassMeritList';
import SubMeritList from './Exam/Rank/SubMeritList';
import SelectedSubMeritList from './Exam/Rank/SelectedSubMeritList';
import SansthaConsolidatedReport from './Budget/BudgetReports/SansthaConsolidatedReport';
import SansthaConsolidatedEnglishReport from './Budget/BudgetReports/SansthaConsolidatedEnglishReport';
import LeaveMaster from './Payrole/Master/LeaveMaster';
import HolidayMarking from './Payrole/Master/HolidayMarking';
import EmployeeLeaveEntry from './Payrole/Transaction/EmployeeLeaveEntry';
import BoardExamMarkEntry from './Exam/ExamMaster/AfterExam/BoardExamMarkEntry';
import BoardExamMarkEntryTable from './Exam/ExamMaster/AfterExam/BoardExamMarkEntryTable';
import EmployeeLeaveOpening from './Payrole/Transaction/EmployeeLeaveOpening';
import EmployeeBiometricAttendace from './Payrole/Transaction/EmployeeBiometricAttendace';
import EmployeeAttendanceReport from './Payrole/Reports/EmployeeAttendanceReport';
import EmployeeLeaveEntryReport from './Payrole/Reports/EmployeeLeaveEntryReport';
import PayrollDashboard from './Payrole/PayrollDashboard';
import GuardianDetail from './Masters/Modal/GuardianDetail';
import BuildingMaster from './Hostel/Master/BuildingMaster';
import RoomMaster from './Hostel/Master/RoomMaster';
import BedMaster from './Hostel/Master/BedMaster';
import StudentLeaveMaster from './Hostel/Master/StudentLeaveMaster';
import MaterialMaster from './Hostel/Master/MaterialMaster';
import HostelAllocation from './Hostel/Transaction/HostelAllocation';
import MaterialAllocation from './Hostel/Transaction/MaterialAllocation';
import MedicalVisitForm from './Hostel/Transaction/MedicalVisitForm';
import StudentLeaveForm from './Hostel/Transaction/StudentLeaveForm';
import StudentBedAllocationReport from './Hostel/Reports/StudentBedAllocationReport';
import VacantBedReport from './Hostel/Reports/VacantBedReport';
import MaterialReport from './Hostel/Reports/MaterialReport';
import MedicalVisitReport from './Hostel/Reports/MedicalVisitReport';
import LeaveReport from './Hostel/Reports/LeaveReport';
import ReceiptPaymentGroupMaster from './AccountMaster/Modal/ReceiptPaymentGroupMaster';
import PrapatraB from './Exam/ExamReport/PrapatraB';
import HostelDashboard from './Hostel/HostelDashboard';
import ItemCategoryMaster from './InventoryStore/Master/ItemCategoryMaster';
import ItemMaster from './InventoryStore/Master/ItemMaster';
import InventoryVendorMaster from './InventoryStore/Master/InventoryVendorMaster';
import OpeningItem from './InventoryStore/Transaction/OpeningItem';
import ItemInward from './InventoryStore/Transaction/ItemInward';
import ItemInwardTable from './InventoryStore/Transaction/ItemInwardTable';
import ItemOutward from './InventoryStore/Transaction/ItemOutward';
import GroupHead from './AccountMaster/Modal/GroupHead';
import GradeExamMarkEntry from './Exam/ExamMaster/GraceExamMarkEntry';
import AdmissionEntranceTable from './Admission/AdmissionMaster/AdmissionEntranceTable';
import ProgressCardMessage from './Exam/ExamMaster/AfterExam/ProgressCardMessage';
import AdmissionEntranceForm from './Admission/AdmissionMaster/AdmissionEntranceForm';
import MasterFormAccountMasterTable from './AccountMaster/Modal/MasterFormAccountMasterTable';
import MasterFormAccountGroupTable from './AccountMaster/Modal/MasterFormAccountGroupTable';
import ReceiptPaymentGroupMasterTable from './AccountMaster/Modal/ReceiptPaymentGroupMasterTable';
import GroupHeadTable from './AccountMaster/Modal/GroupHeadTable';
import InventoryVendorMasterTable from './InventoryStore/Master/InventoryVendorMasterTable';
import ItemMasterTable from './InventoryStore/Master/ItemMasterTable';
import OpeningItemTable from './InventoryStore/Transaction/OpeningItemTable';
import ItemOutwardTable from './InventoryStore/Transaction/ItemOutwardTable';
import ExamMarkStatus from './Exam/ExamReport/ExamMarkStatus';
import SubjectObservationMaster from './Exam/ExamMaster/SubjectObservationMaster';
import TermObservationMaster from './Exam/ExamMaster/TermObservationMaster';
import ResultSheetMessage from './Exam/ExamMaster/AfterExam/ResultSheetMessage';
import SubjectWiseObservation from './Exam/ExamMaster/AfterExam/SubjectWiseObservation';
import TermWiseObservation from './Exam/ExamMaster/AfterExam/TermWiseObservation';
import SansthaAdminPage from './Sanstha/SansthaAccount/SansthaAdminPage';
import AccountGroupUpdateForm from './Sanstha/SansthaAccount/AccountGroupUpdateForm';
import AccountClosingTransfer from './AccountMaster/Transaction/AccountClosingTransfer';
import { useSelector } from 'react-redux';
import DualVoucherEntryTable from './AccountMaster/Modal/DualVoucherEntryTable';
import DualVoucherEntryForm from './AccountMaster/Modal/DualVoucherEntryForm';
import Daybook from './AccountMaster/Reports/Daybook';
import AwardDistribution from './Academic/Modal/AwardDistribution';
import AwardDistributionEntry from './Academic/Modal/AwardDistributionEntry';
import AttendanceCertificate from './Academic/Modal/AttendanceCertificate';
import CashEntryForm from './AccountMaster/Modal/DualVoucherForms/CashEntryForm';
import BankEntryForm from './AccountMaster/Modal/DualVoucherForms/BankEntryForm';
import JVEntryForm from './AccountMaster/Modal/DualVoucherForms/JVEntryForm';
import FeeReceiptForm from './FeeManagement/Masters/FeeReceiptForm';
import FeeReceiptTables from './FeeManagement/Masters/FeeReceiptTables';
import FeeCollectionSummary from './FeeManagement/FeeReports/FeeCollectionSummary';
import BoardExamMarkAllocListReport from './Exam/ExamReport/BoardExamMarkAllocListReport';
import BoardExamMarkSummaryReport from './Exam/ExamReport/BoardExamMarkSummaryReport';
import ManageTimeSlot from './Academic/Modal/ManageTimeSlot';
import ManageTimeSlotTable from './Academic/Modal/ManageTimeSlotTable';
import ManageTimeTable from './Academic/Modal/ManageTimeTable';
import TotalTimeTableReport from './Academic/AcademicReport/TotalTimeTableReport';
import EmployeeTimeTableReport from './Academic/AcademicReport/EmployeeTimeTableReport';
import StudentReporting from './Hostel/Transaction/StudentReporting';
import StudentReportingReport from './Hostel/Reports/StudentReportingReport';
import TeachersLectureCount from './Academic/AcademicReport/TeachersLectureCount';
import ExamTimeTableForm from './Academic/Modal/ExamTimeTableForm';
import ExamTimeTable from './Academic/Modal/ExamTimeTable';
import MobileExamTimeTable from './MobileApp/Module/MobileExamTimeTable';
import ExternalExamForm from './Masters/Modal/ExternalExamForm';
import ExternalExamAllocation from './Academic/Modal/ExternalExamAllocation';
import ExternalExamAllocationReport from './Academic/AcademicReport/ExternalExamAllocationReport';
import TypeMaster from './AccountMaster/Modal/TypeMaster';
import InvestmentEntry from './AccountMaster/Modal/InvestmentEntry';
import InvetmentEntryTable from './AccountMaster/Modal/InvetmentEntryTable';
import InvestmentReport from './AccountMaster/Reports/InvestmentReport';
import SeniorityEmployeeForm from './Masters/Modal/SeniorityEmployeeForm';
import StudentDailyAttendance from './Academic/Catalog/StudentDailyAttendance';
import StudentDailyAttendReport from './Academic/CatalogReport/StudentDailyAttendReport';
import DailyAttendaceSummary from './Academic/CatalogReport/DailyAttendaceSummary';


const MasterContent = () => {

    const authUser = useSelector((state) => state.user.value);

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object


    return (
        <>
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper" style={{ backgroundColor: "#D6EAF8" }}>
                        <div className="container">
                            <Routes>
                                {/* Master */}
                                <Route exact path="/dashboard/" element={<Dashboard />} />
                                <Route exact path="/m001/:formid" element={<MasterForm3Label />} />
                                <Route exact path="/m002/:formid" element={<MasterForm4Label />} />
                                <Route exact path="/m003/:formid" element={<MasterFormSubCast />} />
                                <Route exact path="/m004/:formid" element={<MasterFormStudentGrade />} />
                                <Route exact path="/m005/:formid" element={<MasterFormClass />} />
                                <Route exact path="/m006/:formid" element={<MasterFormDivision />} />
                                <Route exact path="/m007/:formid" element={<MasterFormExam />} />
                                <Route exact path="/m008/:formid" element={<MasterFormSubExam />} />
                                <Route exact path="/m009F/:empId/:EditFlag" element={<MasterFormEmployee />} />
                                <Route exact path="/m009/:toastFlag" element={<MasterFormEmployeeTable />} />
                                <Route exact path="/m009sF/" element={<SeniorityEmployeeForm />} />
                                <Route exact path="/m129F/:instId/:EditFlag" element={<InstituteMaster />} />
                                <Route exact path="/m129/:toastFlag" element={<InstituteMasterTable />} />
                                <Route exact path="/m125/" element={<MasterReport />} />
                                <Route exact path="/m130/" element={<FeeMaster />} />
                                <Route exact path="/m126/" element={<EmployeeReport />} />
                                <Route exact path="/m131/" element={<StudentEntryExcel />} />
                                <Route exact path="/m01/:formid" element={<MasterForm2Label />} />
                                <Route exact path="/m137/" element={<GuardianDetail />} />
                                <Route exact path="/m138/" element={<ExternalExamForm />} />

                                {/* Administration */}
                                <Route exact path="/ad01F/:rno/:EditFlag" element={<MasterFormStudent />} />
                                <Route exact path="/ad01/:toastFlag" element={<MasterFormStudentTable />} />
                                <Route exact path="/ad207F/:lcno/:regNum/:EditFlag" element={<LeavingCertificateForm />} />
                                <Route exact path="/ad213/" element={<StudentAadhar />} />
                                <Route exact path="/ad207/" element={<LeavingCertificateTable />} />
                                <Route exact path="/ad207MF/" element={<ManualLeavingCertificateForm />} />
                                <Route exact path="/ad202/" element={<StudentBankDetails />} />
                                <Route exact path="/ad205/" element={<CasteCategoryAssignment />} />
                                <Route exact path="/ad206/" element={<MinorityStatusAssignment />} />
                                <Route exact path="/ad209/" element={<ParentPhone />} />
                                <Route exact path="/ad211/" element={<StudentCWSN />} />
                                <Route exact path="/ad215/" element={<StudentMedium />} />
                                <Route exact path="/ad217/" element={<SemiEnglishStudents />} />
                                <Route exact path="/ad228/" element={<BplStudents />} />
                                <Route exact path="/ad218/" element={<StudentAdmType />} />
                                <Route exact path="/ad221/" element={<Bonafide />} />
                                <Route exact path="/ad221MbF/" element={<ManualBonafide />} />
                                <Route exact path="/ad212/" element={<StudentReligion />} />
                                <Route exact path="/ad214/" element={<StudentSaral />} />
                                <Route exact path="/ad222/" element={<LcPrint />} />
                                <Route exact path="/ad223/" element={<CategorywiseStudReport />} />
                                <Route exact path="/ad224/" element={<MinorityReport />} />
                                <Route exact path="/ad219/" element={<GeneralRegister />} />
                                <Route exact path="/ad220/" element={<GeneralRegisterChecklist />} />
                                <Route exact path="/ad208/" element={<PramotShiftStudents />} />
                                <Route exact path="/ad229/" element={<TransferStudents />} />
                                <Route exact path="/ad210/" element={<StudentsBulkLc />} />
                                <Route exact path="/ad210F/" element={<LcDefault />} />
                                <Route exact path="/ad216/" element={<StudentAllowences />} />
                                <Route exact path="/ad230/" element={<LcSummary />} />
                                <Route exact path="/ad203/" element={<StudentsMotherTongue />} />
                                <Route exact path="/ad204/" element={<StudentVillageUpdation />} />
                                <Route exact path="/ad231/" element={<CategorySummary />} />
                                <Route exact path="/ad225/" element={<SchoolStatistics />} />
                                <Route exact path="/ad226/" element={<StudAgeStatistics />} />
                                <Route exact path="/ad227/" element={<UdiesEReport />} />
                                <Route exact path="/ad232/" element={<StudentsIdCard />} />
                                <Route exact path="/ad233/" element={<IdCardPrintingReport />} />
                                <Route exact path="/ad234/" element={<ParentData />} />
                                <Route exact path="/ad235/" element={<StudentNationalCode />} />
                                <Route exact path="/ad236/" element={<StudentNationalCodeReport />} />

                                {/* Account */}
                                <Route exact path="/ac01/" element={<MasterFormAccountGroupTable />} />
                                <Route exact path="/ac01F/:acgId/:EditFlag" element={<MasterFormAccountGroup />} />
                                <Route exact path="/ac02/" element={<MasterFormAccountMasterTable />} />
                                <Route exact path="/ac02F/:accId/:EditFlag" element={<MasterFormAccountMaster />} />
                                <Route exact path="/ac03/" element={<MasterFormNarrationMaster />} />


                                {/* DualVoucherEntryForm */}
                                {/* {
                                    (function () {
                                        // Function Logic Here. 
                                        if (authUser.accountingType == 1) {
                                            return (<>
                                                <Route exact path="/ac04F/:vno/:EditFlag" element={<VoucherEntryForm />} />
                                                <Route exact path="/ac04/:toastFlag" element={<VoucherEntryTable />} />
                                            </>)
                                        }
                                        else {
                                            return (<>
                                                <Route exact path="/ac04/:toastFlag" element={<DualVoucherEntryTable />} /> */}
                                {/* <Route exact path="/ac04F/:vno/:EditFlag" element={<DualVoucherEntryForm />} /> */}
                                {/* <Route exact path="/ac04F1/:vno/:EditFlag" element={<CashEntryForm />} />
                                                <Route exact path="/ac04F2/:vno/:EditFlag" element={<BankEntryForm />} />
                                                <Route exact path="/ac04F3/:vno/:EditFlag" element={<JVEntryForm />} />
                                            </>)
                                        }
                                    })()
                                } */}

                                {(() => {
                                    if (disableEditFlag && disableEditFlag[5] == 1) {
                                        return (
                                            <>
                                                <Route exact path="/ac04/:toastFlag" element={<DualVoucherEntryTable />} />
                                                <Route exact path="/ac04F1/:vno/:EditFlag" element={<CashEntryForm />} />
                                                <Route exact path="/ac04F2/:vno/:EditFlag" element={<BankEntryForm />} />
                                                <Route exact path="/ac04F3/:vno/:EditFlag" element={<JVEntryForm />} />
                                            </>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <Route exact path="/ac04F/:vno/:EditFlag" element={<VoucherEntryForm />} />
                                                <Route exact path="/ac04/:toastFlag" element={<VoucherEntryTable />} />
                                            </>
                                        )
                                    }
                                })()}


                                <Route exact path="/ac618/" element={<ReceiptPaymentGroupMasterTable />} />
                                <Route exact path="/ac618F/:rpId/:EditFlag" element={<ReceiptPaymentGroupMaster />} />
                                <Route exact path="/ac619/" element={<GroupHeadTable />} />
                                <Route exact path="/ac619F/:ghId/:EditFlag" element={<GroupHead />} />
                                <Route exact path="/ar01/" element={<AccountLedger />} />
                                <Route exact path="/ar02/" element={<ReceiptAndPayment />} />
                                <Route exact path="/ar03/" element={<IncomeAndExpenditure />} />
                                <Route exact path="/ar04/" element={<BalanceSheet />} />
                                <Route exact path="/ar05/" element={<KirdReport />} />
                                <Route exact path="/ar06/" element={<Daybook />} />
                                <Route exact path="/ar615/" element={<AccountMasterReport />} />
                                <Route exact path="/ar616/" element={<ReceiptAndChalanReport />} />
                                <Route exact path="/ar617/" element={<AccountSummary />} />
                                <Route exact path="/acr611/" element={<TaslamatCertificate />} />
                                <Route exact path="/acr612/" element={<LoanCertificate />} />
                                <Route exact path="/acr613/" element={<AnamatCertificate />} />
                                <Route exact path="/acr614/" element={<MudranCertificate />} />


                                {/* Academics */}
                                <Route exact path="/acd401/" element={<RollNoAssign />} />
                                <Route exact path="/acd403/" element={<ClassTeacherAllocation />} />
                                <Route exact path="/acd414/" element={<StudentAttendance />} />
                                <Route exact path="/acd402/" element={<SubjectAllocation />} />
                                <Route exact path="/acd417/" element={<LessonPlan />} />
                                <Route exact path="/acd418/" element={<MonthlyLessonPlan />} />
                                <Route exact path="/acd416/" element={<StudentBatch />} />
                                <Route exact path="/acd415/" element={<MonthlyWorkDays />} />
                                <Route exact path="/acd407/" element={<SubjectAllocationReport />} />
                                <Route exact path="/acd408/" element={<ClassTeacherAllocationReport />} />
                                <Route exact path="/acd419/" element={<CatalogReport />} />
                                <Route exact path="/acd420/" element={<CatalogEndOfMonth />} />
                                <Route exact path="/ss420F/" element={<SetSubjectSerial />} />
                                <Route exact path="/acd422/" element={<CatalogAllStd />} />
                                <Route exact path="/acd421/" element={<CatalogSubject />} />
                                <Route exact path="/acd404/" element={<SubjectAllocationToTeacher />} />
                                <Route exact path="/acd423/" element={<SubjectToTeacherAllocationReport />} />
                                <Route exact path="/acd424/" element={<StudentWiseSubjectAllocation />} />
                                <Route exact path="/acd425/" element={<StudentWiseSubjectReport />} />
                                <Route exact path="/acd409/" element={<EBCSummary />} />
                                <Route exact path="/acd410/" element={<EBCReport />} />
                                <Route exact path="/acd426/" element={<CurrentStudentAgeList />} />
                                <Route exact path="/acd411/:toastFlag" element={<AwardDistribution />} />
                                <Route exact path="/acd411F/:tId/:EditFlag" element={<AwardDistributionEntry />} />
                                <Route exact path="/acd427/" element={<AttendanceCertificate />} />
                                <Route exact path="/acd406/:toastFlag" element={<ManageTimeSlotTable />} />
                                <Route exact path="/acd406F/:mtsNo/:EditFlag" element={<ManageTimeSlot />} />
                                <Route exact path="/acd405/" element={<ManageTimeTable />} />
                                <Route exact path="/acd412/" element={<TotalTimeTableReport />} />
                                <Route exact path="/acd413/" element={<EmployeeTimeTableReport />} />
                                <Route exact path="/acd428/" element={<TeachersLectureCount />} />
                                <Route exact path="/acd429F/:exId/:EditFlag" element={<ExamTimeTableForm />} />
                                <Route exact path="/acd429/" element={<ExamTimeTable />} />
                                <Route exact path="/acd430/" element={<ExternalExamAllocation />} />
                                <Route exact path="/acd431/" element={<ExternalExamAllocationReport />} />
                                <Route exact path="/acd432/" element={<StudentDailyAttendance />} />
                                <Route exact path="/acd433/" element={<StudentDailyAttendReport />} />
                                <Route exact path="/acd434/" element={<DailyAttendaceSummary />} />


                                <Route exact path="/404/" element={<PageNotFound />} />

                                {/* Exam */}
                                <Route exact path="/ex501/" element={<ExamSubExamAllocation />} />
                                <Route exact path="/ex502/" element={<ExamMarkAllocation />} />
                                <Route exact path="/ex503/" element={<ExamMarkEntry />} />
                                <Route exact path="/ex530/" element={<ExamAllocationReport />} />
                                <Route exact path="/ex531/" element={<ExamMarkAllocationReport />} />
                                <Route exact path="/ex534/" element={<ExamResultSetting />} />
                                <Route exact path="/ex532/" element={<SubjectMarkSheet />} />
                                <Route exact path="/ex521/" element={<PrapatraA />} />
                                <Route exact path="/ex522/" element={<FreqDistribution />} />
                                <Route exact path="/ex520/" element={<ProgressCard />} />
                                <Route exact path="/ex523/" element={<SingleSubExamWiseReport />} />
                                <Route exact path="/ex524/" element={<CumulativeRecord />} />
                                <Route exact path="/ex505/" element={<StudentRTEResult />} />
                                <Route exact path="/ex510/" element={<StudInternalSeatNo />} />
                                <Route exact path="/ex519/" element={<ResultSheet />} />
                                <Route exact path="/ex517/" element={<TermWiseHealth />} />
                                <Route exact path="/ex535/" element={<StudInternalSeatNoReport />} />
                                <Route exact path="/ex508/" element={<BulkMarkAllocation />} />
                                <Route exact path="/ex536/" element={<StudentBoardSeatNo />} />
                                <Route exact path="/ex537/" element={<StudentBoardSeatNoReport />} />
                                <Route exact path="/ex538/" element={<BoardInternalAssessmentReport />} />
                                <Route exact path="/ex539F/:sNo/:EditFlag" element={<BoardExamMarkEntry />} />
                                <Route exact path="/ex539/" element={<BoardExamMarkEntryTable />} />
                                <Route exact path="/ex540/" element={<PrapatraB />} />
                                <Route exact path="/ex541/" element={<GradeExamMarkEntry />} />
                                <Route exact path="/ex515/" element={<ResultSheetMessage />} />
                                <Route exact path="/ex516/" element={<ProgressCardMessage />} />
                                <Route exact path="/ex542/" element={<ExamMarkStatus />} />
                                <Route exact path="/ex507/" element={<SubjectObservationMaster />} />
                                <Route exact path="/ex506/" element={<TermObservationMaster />} />
                                <Route exact path="/ex518/" element={<SubjectWiseObservation />} />
                                <Route exact path="/ex514/" element={<TermWiseObservation />} />
                                <Route exact path="/ex543/" element={<TermWiseObservationReport />} />
                                <Route exact path="/ex544/" element={<SubjectWiseObservationReport />} />
                                <Route exact path="/ex545/" element={<BoardExamMarkAllocListReport />} />
                                <Route exact path="/ex546/" element={<BoardExamMarkSummaryReport />} />


                                {/* Rank */}
                                <Route exact path="/r525/" element={<StudentRank />} />
                                <Route exact path="/r528/" element={<ClassMeritList />} />
                                <Route exact path="/r529/" element={<SubMeritList />} />
                                {/* <Route exact path="/r527/" element={<SelectedSubMeritList />} /> */}

                                {/* Fee */}
                                <Route exact path="/f310/" element={<FeeDemand />} />
                                <Route exact path="/f311F/:tId/:EditFlag" element={<FeeReceiptForm />} />
                                <Route exact path="/f311/:toastFlag" element={<FeeReceiptTables />} />
                                <Route exact path="/f316/" element={<FeeReceiptSummary />} />
                                <Route exact path="/f317/" element={<FeeDueList />} />
                                <Route exact path="/f309/" element={<BulkFeeReceipt />} />
                                <Route exact path="/f318/" element={<FeeDemandSummaryReport />} />
                                <Route exact path="/f314F/:dNo/:EditFlag" element={<DengiPavati />} />
                                <Route exact path="/f314/:toastFlag" element={<DengiPavatiTable />} />
                                <Route exact path="/f323/" element={<StudentWiseFeeDemand />} />
                                <Route exact path="/f319/" element={<YearlyFeeCollection />} />
                                <Route exact path="/f324/" element={<SansthaFeeReceiptSummary />} />
                                <Route exact path="/f325/" element={<EBCFeeDemand />} />
                                <Route exact path="/f326/" element={<FeeCollectionSummary />} />


                                {/* Sanstha Account */}
                                <Route exact path="/s701/" element={<SansthaNotice />} />
                                <Route exact path="/f322F/:dNo/:EditFlag" element={<SansthaReceipt />} />
                                <Route exact path="/f322/:toastFlag" element={<SansthaReceiptTable />} />
                                <Route exact path="/f321/" element={<DengiPavatiReport />} />
                                <Route exact path="/f312/" element={<DengiPavatiPohochReport />} />
                                <Route exact path="/f313/" element={<DengiPavatiPohoch />} />
                                <Route exact path="/f320/" element={<FeeReceiptSummaryExcelReport />} />
                                <Route exact path="/s618/" element={<UserErrorSubmissionForm />} />
                                <Route exact path="/act620/" element={<AccountClosingTransfer />} />

                                {/* Admission */}
                                <Route exact path="/adm301/" element={<AdmissionFormSale />} />
                                <Route exact path="/adm302/:toastFlag" element={<AdmissionFormReceiveTable />} />
                                <Route exact path="/adm302F/:fNo/:EditFlag" element={<AdmissionFormReceive />} />
                                <Route exact path="/adm304/" element={<AdmissionFormCancel />} />
                                <Route exact path="/adm303/" element={<AdmissionConfirm />} />
                                <Route exact path="/adm322F/" element={<AdmissionEntranceForm />} />
                                <Route exact path="/adm322/" element={<AdmissionEntranceTable />} />
                                <Route exact path="/adm307/" element={<AdmissionMeritList />} />
                                <Route exact path="/adm305/" element={<AdmissionFormSaleReport />} />
                                <Route exact path="/adm306/" element={<AdmissionFormReceiveReport />} />

                                {/* Sanstha Endowment */}
                                <Route exact path="/EndowmentDash/" element={<EndowmentDashboard />} />
                                <Route exact path="/m701F/:eId/:EditFlag" element={<EndowmentMaster />} />
                                <Route exact path="/m701/:toastFlag" element={<EndowmentMasterTable />} />
                                <Route exact path="/m715/" element={<EndowmentSubjectMaster />} />
                                <Route exact path="/er704/" element={<EndowmentList />} />
                                <Route exact path="/er705/" element={<EndowmentWithConditions />} />
                                <Route exact path="/et706/" element={<EndowmentInterestCalculation />} />
                                <Route exact path="/er708/" element={<SchoolWiseEndowment />} />
                                <Route exact path="/er709/" element={<SansthaAwardDistribution />} />
                                <Route exact path="/er703/" element={<EndowmentInterestReport />} />
                                <Route exact path="/er710/" element={<SansthaAwardBlank />} />
                                <Route exact path="/er711/" element={<SansthaAwardDistSummary />} />
                                <Route exact path="/er712/" element={<ClassWiseEndowmentReport />} />
                                <Route exact path="/er713/" element={<SubjectWiseEndowmentReport />} />
                                <Route exact path="/er714/" element={<GenderWiseEndowmentReport />} />
                                <Route exact path="/er716/" element={<EndowmentRegisterReport />} />
                                <Route exact path="/er717/" element={<SchoolWiseEndowWiseDetailReport />} />

                                {/* Sanstha Account */}
                                {/* <Route exact path="/sacc001/" element={<AdminVoucherEntryPage />} /> */}
                                <Route exact path="/sacc001/" element={<SansthaAdminPage />} />

                                {/* Library Master Forms */}
                                <Route exact path="/LibraryDashboard/" element={<LibraryDashboard />} />
                                <Route exact path="/lm001F/:formid/:mId/:EditFlag" element={<LibraryMaster2LabelForm />} />
                                <Route exact path="/lm001/:formid" element={<LibraryMaster2LabelTable />} />
                                <Route exact path="/l805F/:tId/:EditFlag" element={<TitleMaster />} />
                                <Route exact path="/l805/" element={<TitleMasterTable />} />
                                <Route exact path="/l809F/:rId/:EditFlag" element={<RegisterTypeMaster />} />
                                <Route exact path="/l809/" element={<RegisterTypeMasterTable />} />
                                <Route exact path="/l804F/:aId/:EditFlag" element={<AuthorMaster />} />
                                <Route exact path="/l804/" element={<AuthorMasterTable />} />
                                <Route exact path="/l811F/:sId/:EditFlag" element={<SubBudgetTypeMaster />} />
                                <Route exact path="/l811/" element={<SubBudgetTypeMasterTable />} />
                                <Route exact path="/l812F/:mtId/:EditFlag" element={<MemberTypeMaster />} />
                                <Route exact path="/l812/" element={<MemberTypeMasterTable />} />
                                <Route exact path="/l808F/:pId/:EditFlag" element={<PublisherMaster />} />
                                <Route exact path="/l808/" element={<PublisherMasterTable />} />
                                <Route exact path="/l807F/:vId/:EditFlag" element={<VendorMaster />} />
                                <Route exact path="/l807/" element={<VendorMasterTable />} />
                                <Route exact path="/l813F/:bId/:EditFlag" element={<BinderMaster />} />
                                <Route exact path="/l813/" element={<BinderMasterTable />} />

                                {/* Library Acquisition Forms */}
                                <Route exact path="/la901F/:acId/:EditFlag" element={<AccessionEntry />} />
                                <Route exact path="/la901/" element={<AccessionEntryTable />} />
                                <Route exact path="/la902/" element={<AccessionReport />} />

                                {/* Library Circulation Forms */}
                                <Route exact path="/lc903/" element={<BookIssueReturn />} />
                                <Route exact path="/lc904/" element={<WithdrawalEntryTable />} />
                                <Route exact path="/lc904F/" element={<WithdrawalEntry />} />
                                <Route exact path="/lc906/" element={<BookBankIssue />} />
                                <Route exact path="/lr814/" element={<LibraryMasterReport />} />
                                <Route exact path="/cr915/" element={<DailyTransaction />} />
                                <Route exact path="/cr916/" element={<DefaulterList />} />
                                <Route exact path="/cr917/" element={<BookIssueRegister />} />
                                <Route exact path="/cr918/" element={<BookReturnRegister />} />
                                <Route exact path="/cr911/" element={<BookBarcode />} />
                                <Route exact path="/cr913/" element={<BookBankReport />} />
                                <Route exact path="/cr920/" element={<WithdrawalRegisterReport />} />
                                <Route exact path="/cr919/" element={<MemberStatement />} />


                                {/* Mobile App */}
                                <Route exact path="/ma1001/" element={<RegistrationApproval />} />
                                <Route exact path="/ma1002F/" element={<Notice />} />
                                <Route exact path="/ma1002/" element={<NoticeTable />} />
                                <Route exact path="/ma1003F/:hId/:EditFlag" element={<HomeWork />} />
                                <Route exact path="/ma1003/" element={<HomeWorkTable />} />
                                <Route exact path="/ma1004F/:eId/:EditFlag" element={<Event />} />
                                <Route exact path="/ma1004/" element={<EventTable />} />
                                <Route exact path="/ma1005/" element={<MobileExamTimeTable />} />

                                {/* Budget */}
                                <Route exact path="/BudgetDashboard/" element={<BudgetDashboard />} />
                                <Route exact path="/bm1005F/:bId/:EditFlag" element={<BudgetCategories />} />
                                <Route exact path="/bm1005/" element={<BudgetCategoryTable />} />
                                <Route exact path="/bm1006F/:baId/:EditFlag" element={<BudgetAccountForm />} />
                                <Route exact path="/bm1006/" element={<BudgetAccountTable />} />
                                <Route exact path="/bt1007/" element={<BudgetEntryForm />} />
                                <Route exact path="/br1008/" element={<BudgetConsolidatedDepartmentWiseReport />} />
                                <Route exact path="/br1009/" element={<SchoolWiseBudgetReport />} />
                                <Route exact path="/br1010/" element={<BudgetReport />} />
                                <Route exact path="/b1011/" element={<BudgetNoAndDateForm />} />
                                <Route exact path="/bt1013/" element={<SansthaBudgetEntryForm />} />
                                <Route exact path="/br1012/" element={<SainikSchoolHostelBudgetReport />} />
                                <Route exact path="/br1014/" element={<SansthaBudgetReport />} />
                                <Route exact path="/br1015/" element={<SansthaConsolidatedReport />} />
                                <Route exact path="/br1016/" element={<SansthaConsolidatedEnglishReport />} />

                                {/* Payrole */}
                                <Route exact path="/payrollDashboard/" element={<PayrollDashboard />} />
                                <Route exact path="/pm1101/" element={<LeaveMaster />} />
                                <Route exact path="/pm1102/" element={<HolidayMarking />} />
                                <Route exact path="/pt1103/" element={<EmployeeBiometricAttendace />} />
                                <Route exact path="/pt1104/" element={<EmployeeLeaveEntry />} />
                                <Route exact path="/pt1105/" element={<EmployeeLeaveOpening />} />
                                <Route exact path="/pr1107/" element={<EmployeeAttendanceReport />} />
                                <Route exact path="/pr1108/" element={<EmployeeLeaveEntryReport />} />

                                {/* Hostel */}
                                <Route exact path="/HostelDashboard/" element={<HostelDashboard />} />
                                <Route exact path="/hm1201/" element={<BuildingMaster />} />
                                <Route exact path="/hm1202/" element={<RoomMaster />} />
                                <Route exact path="/hm1203/" element={<BedMaster />} />
                                <Route exact path="/hm1204/" element={<StudentLeaveMaster />} />
                                <Route exact path="/hm1205/" element={<MaterialMaster />} />
                                <Route exact path="/ht1206/" element={<HostelAllocation />} />
                                <Route exact path="/ht1207/" element={<MaterialAllocation />} />
                                <Route exact path="/ht1208/" element={<MedicalVisitForm />} />
                                <Route exact path="/ht1209/" element={<StudentLeaveForm />} />
                                <Route exact path="/hr1210/" element={<StudentBedAllocationReport />} />
                                <Route exact path="/hr1211/" element={<VacantBedReport />} />
                                <Route exact path="/hr1212/" element={<MaterialReport />} />
                                <Route exact path="/hr1213/" element={<MedicalVisitReport />} />
                                <Route exact path="/hr1214/" element={<LeaveReport />} />
                                <Route exact path="/ht1215/" element={<StudentReporting />} />
                                <Route exact path="/hr1216/" element={<StudentReportingReport />} />


                                {/* Inventory Store */}
                                <Route exact path="/sm1301/" element={<ItemCategoryMaster />} />
                                <Route exact path="/sm1302F/:itId/:EditFlag" element={<ItemMaster />} />
                                <Route exact path="/sm1302/" element={<ItemMasterTable />} />
                                <Route exact path="/sm1303F/:venId/:EditFlag" element={<InventoryVendorMaster />} />
                                <Route exact path="/sm1303/" element={<InventoryVendorMasterTable />} />
                                <Route exact path="/st1304F/:opId/:EditFlag" element={<OpeningItem />} />
                                <Route exact path="/st1304/" element={<OpeningItemTable />} />
                                <Route exact path="/st1305F/:inId/:EditFlag" element={<ItemInward />} />
                                <Route exact path="/st1305/" element={<ItemInwardTable />} />
                                <Route exact path="/st1306F/:ioId/:EditFlag" element={<ItemOutward />} />
                                <Route exact path="/st1306/" element={<ItemOutwardTable />} />

                                {/* Investment */}
                                <Route exact path="/aci622/" element={<TypeMaster />} />
                                <Route exact path="/aci623/" element={<InvetmentEntryTable />} />
                                <Route exact path="/aci623F/:ieId/:EditFlag" element={<InvestmentEntry />} />
                                <Route exact path="/aci624/" element={<InvestmentReport />} />

                            </Routes >
                        </div>
                    </div>
                </div>
            </div>
            <ToggleSideMenu />
        </>

    )
}

export default MasterContent;